<template>
  <div class="resetPassphraseWithToken flex justify-center items-center">
    <TokenStatus
        operation="CONFIRM_RESET_PASSPHRASE"
        :token="resetPassphraseToken"
        tokenName="resetPassphraseToken"
        @token-is-valid="() => tokenIsValid=true"
        @token-is-invalid="() => tokenIsValid=false"
        @token-has-expired="() => tokenIsValid=false"
    ></TokenStatus>

    <Transition>
      <!--  Form to reset password -->
      <form
          v-if="tokenIsValid"
          class="form"
      >
        <h1 class="heading2">Change Password
          <hr class="gradientLine">
        </h1>

        <div>
          <label for="password">Password</label>
          <TextInput
              :tabIndex="1"
              id="password"
              name="password"
              type="password"
              placeholder="Your passphrase"
          ></TextInput>
          <LFieldError></LFieldError>
        </div>
        <div>
          <label for="passwordRepeat">Confirm Password</label>
          <TextInput
              :tabindex="2"
              id="passwordRepeat"
              name="passwordRepeat"
              type="password"
              placeholder="Repeat your passphrase"
          >
          </TextInput>
          <LFieldError></LFieldError>
        </div>

        <div class="flex items-center justify-between">
          <button
              class="button primary"
              type="button"
              :tabindex="2">
            Change password
          </button>
        </div>
      </form>
    </Transition>
  </div>
</template>

<script>
import TextInput from "@/components/forms/TextInput.vue";
import LFieldError from "@/components/layout/LFieldError";
import {ref, watch} from "vue";
import TokenStatus from "@/components/authentication/TokenStatus.vue";
import {CONFIRM_RESET_PASSPHRASE} from "@/store/operations";
import {useStatusMessage} from "@/composables/useStatusMessage";

export default {
  name: "ResetPassphraseWithToken",
  components: {
    TextInput,
    LFieldError,
    TokenStatus,
  },
  props: {
    resetPassphraseToken: {
      type: String,
      required: true
    },
  },
  setup() {
    const tokenIsValid = ref(null)
    const {setMessage, deleteMessage} = useStatusMessage(CONFIRM_RESET_PASSPHRASE)

    deleteMessage()

    watch(tokenIsValid, value => {
      if (value === true) setMessage({isSuccess: true, body: "Your token is valid."})
      else if (value === false) setMessage({
        isError: true,
        body: 'Your token is invalid. Please <a href="/#/forgotPassword">request a new one</a>.'
      })
    })

    return {
      tokenIsValid,
    }
  }


}
</script>

<style scoped>

</style>